export const HOST = process.env.REACT_APP_API_URL || 'https://dentist-booking-server.service.hodace.network/';

export const IMAGE_HOST = HOST + 'upload/';

export const LINK_DEPOSIT = process.env.REACT_APP_API_LINK_DEPOSIT;
export const LINK_WITHDRAW = process.env.REACT_APP_API_LINK_WITHDRAW;

export const REACT_APP_WSS_URL = process.env.REACT_APP_WSS_URL;
export const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;
export const WSS_BINANCE_URL = process.env.REACT_APP_WSS_BINANCE_URL;
